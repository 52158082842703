import React from "react";
import './styles/Services.css';
import Business_Consultation from '../Components/Assets/Bussiness_consultation.jpg';
import fashion_consultation from '../Components/Assets/fashion-consultation.jpg'
import fabric_consultation from '../Components/Assets/fabric-consultation.jpg'
import Hero from "../Components/Hero/Hero";
import banner from "../Components/Assets/Our_Services.png";
import { Link } from "react-router-dom";


const Services = () => {
    return(
        <div className="under-construction">
            <div className="container">
                <h1>Page Under Construction</h1>
                <p>We're working hard to bring you something amazing! Stay tuned.</p>
                <div className="loader"></div>
            </div>
        </div>
    );
};

export default Services;